import { extend, localize } from "vee-validate";
import { required, min, max, email } from "vee-validate/dist/rules";
import lang from "vee-validate/dist/locale/id.json";

extend("required", required);
extend("email", email);
extend("min", min);
extend("max", max);

localize("id", lang);
