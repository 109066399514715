import { getUserData, isUserLoggedIn } from '@/auth/utils'

const guard = next => {
  if (isUserLoggedIn()) {
    if (getUserData().role == 'superadmin') {
      return next()
    }
    return next({ name: 'misc-not-authorized' })
  }
  return next({ name: 'auth-login' })
}

export default [
  {
    path: '/sa/users',
    name: 'sa-users',
    component: () => import('@/views/pages/superadmin/Users.vue')
  },
  {
    path: '/sa/department',
    name: 'sa-department',
    component: () => import('@/views/pages/superadmin/Department.vue'),
  },
  {
    path: '/sa/dosen',
    name: 'sa-dosen',
    component: () => import('@/views/pages/superadmin/Dosen.vue'),
  },
]
.map(route => {
  route.meta = { guard }
  return route
})