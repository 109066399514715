import Vue from 'vue'
import {
  ToastPlugin, ModalPlugin, PaginationPlugin, BootstrapVue,
} from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2'
import VueTour from 'vue-tour'
import VueCompositionAPI from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import router from './router'
import store from './store'
import App from './App.vue'

import 'moment/locale/id'
import './vee-validate'

// CSS
import 'sweetalert2/dist/sweetalert2.min.css'
import 'choices.js/public/assets/styles/base.min.css'
import 'choices.js/public/assets/styles/choices.min.css'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/toastification'
import '@/libs/vue-select'

require('dotenv').config()

moment.locale('id')

// import { ValidationObserver, ValidationProvider } from 'vee-validate'
// // import VeeValidate from 'vee-validate'
// import VueI18n from 'vue-i18n'
// import validationMessages from 'vee-validate/dist/locale/id'

Vue.config.ignoredElements = ['gjmf-header']
Vue.config.ignoredElements = ['gjmf-header-end']
// SWEETALERT
Vue.use(VueSweetalert2)
// Vue Tour
require('vue-tour/dist/vue-tour.css')

Vue.use(VueTour)
// BSV
Vue.use(BootstrapVue)

// BSV Plugin Registration
Vue.use(ToastPlugin)

// Create $alert prototype extends from Vue Toast Plugin
Vue.prototype.$alert = function (props) {
  this.$toast({
    component: ToastificationContent,
    position: 'top-right',
    props,
  })
}

Vue.prototype.$gjSelesai = 'Selesai'
Vue.prototype.$gjPb = 'Perlu Diperbaiki'
Vue.prototype.$gjDt = 'Dapat Ditinjau'
Vue.prototype.$gjDtu = 'Dapat Ditinjau Ulang'
Vue.prototype.$gjTdk = 'Tidak ada status'
Vue.prototype.$gjPis = 'Proses Pengisian'

Vue.prototype.$variantDropDown = {
  /* eslint-disable key-spacing */
  Selesai : 'success',
  'Dapat Ditinjau'      : 'info',
  'Perlu Diperbaiki'     : 'danger',
  'Dapat Ditinjau Ulang'     : 'info',
  'Proses Pengisian'      : 'warning',
  'Tidak ada status'      : 'secondary',
  /* eslint-enable key-spacing */
}
Vue.prototype.$variantBadge = {
  /* eslint-disable key-spacing */
  Selesai : 'light-success',
  'Dapat Ditinjau'      : 'light-info',
  'Perlu Diperbaiki'     : 'light-danger',
  'Dapat Ditinjau Ulang'     : 'light-info',
  'Proses Pengisian'      : 'light-warning',
  'Tidak ada status'      : 'light-secondary',
  /* eslint-enable key-spacing */
}
Vue.prototype.$variantText = {
  /* eslint-disable key-spacing */
  Selesai : 'success',
  'Dapat Ditinjau'      : 'info',
  'Perlu Diperbaiki'     : 'danger',
  'Dapat Ditinjau Ulang'     : 'info',
  'Proses Pengisian'      : 'warning',
  'Tidak ada status'      : 'secondary',
  /* eslint-enable key-spacing */
}

Vue.use(PaginationPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

// Vue.use(VueI18n);

// const i18n = new VueI18n();
// i18n.locale = "id"; // set a default locale (without it, it won't work)

// // import { ValidationObserver, ValidationProvider } from 'vee-validate'
// Vue.use(ValidationObserver, {
//    i18nRootKey: 'validations', // customize the root path for validation messages.
//    i18n,
//    dictionary: {
//     id: validationMessages
//    }
// });
// Vue.use(ValidationProvider, {
//    i18nRootKey: 'validations', // customize the root path for validation messages.
//    i18n,
//    dictionary: {
//     id: validationMessages
//    }
// });

new Vue({
  router,
  store,
  // VueI18n,
  render: h => h(App),
}).$mount('#app')
