import { getUserData, isUserLoggedIn } from '@/auth/utils'

const guard = next => {
  if (isUserLoggedIn()) {
    if (getUserData().role == 'operator') {
      return next()
    }
    return next({ name: 'misc-not-authorized' })
  }
  return next({ name: 'auth-login' })
}

export default [
  {
    path: '/op/home',
    name: 'op-home',
    component: () => import('@/views/pages/operator/Home.vue'),
  },
  {
    path: '/op/profil',
    name: 'op-profil',
    component: () => import('@/views/pages/operator/Profile.vue'),
  },
  {
    path: '/op/led',
    name: 'op-led',
    component: () => import('@/views/pages/operator/led/Index.vue'),
  },
  {
    path: '/op/led/form/:id',
    name: 'op-led-form',
    component: () => import('@/views/pages/operator/led/Form.vue'),
  },
  {
    path: '/op/lkps/',
    name: 'op-lkps',
    component: () => import('@/views/pages/operator/lkps/Index.vue'),
  },
  {
    path: '/op/lkps/laporan/UPPS-1/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/DPS.vue'),
  },
  {
    path: '/op/lkps/laporan/UPPS-2/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/Keuangan.vue'),
  },
  {
    path: '/op/lkps/laporan/2.2.2-1/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/Kerjasama.vue'),
  },
  {
    path: '/op/lkps/laporan/2.2.2-2/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/Kerjasama.vue'),
  },
  {
    path: '/op/lkps/laporan/2.2.2-3/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/Kerjasama.vue'),
  },
  {
    path: '/op/lkps/laporan/2.2.2-4/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/Kerjasama.vue'),
  },
  // 5
  {
    path: '/op/lkps/laporan/3.1.2.2/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/LKPS_MHSREGULAR.vue'),
  },
  {
    path: '/op/lkps/laporan/3.1.2.3.1/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/CMDN.vue'),
  },
  {
    path: '/op/lkps/laporan/3.1.2.3.2/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/CMDN_N.vue'),
  },
  // 9
  {
    path: '/op/lkps/laporan/3.2.2/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/LKPS_PLDP.vue'),
  },
  {
    path: '/op/lkps/laporan/4.1.2.2/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/DTPS_BKPS.vue'),
  },
  {
    path: '/op/lkps/laporan/4.1.2.3/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/DTPS_BKPS.vue'),
  },
  // 12
  {
    path: '/op/lkps/laporan/4.1.2.4/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/VIEW_LKPS_RDTM.vue'),
  },
  {
    path: '/op/lkps/laporan/4.1.2.5/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/BKDDTPS.vue'),
  },
  {
    path: '/op/lkps/laporan/4.1.2.6-1/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/KMDTSG.vue'),
  },
  {
    path: '/op/lkps/laporan/4.1.2.6-2/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/KMDTSG.vue'),
  },
  {
    path: '/op/lkps/laporan/4.1.2.7/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/JBTASTD.vue'),
  },
  // 16
  {
    path: '/op/lkps/laporan/4.1.2.8/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/LKPS_PRD.vue'),
  },
  {
    path: '/op/lkps/laporan/4.1.2.9-1/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/PKDTPS.vue'),
  },
  {
    path: '/op/lkps/laporan/4.1.2.9-2/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/PKDTPS.vue'),
  },
  {
    path: '/op/lkps/laporan/4.1.2.9-3/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/PKDTPS.vue'),
  },
  {
    path: '/op/lkps/laporan/4.2.2.2/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/Tendik.vue'),
  },
  // 21
  {
    path: '/op/lkps/laporan/4.2.2.3/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/PKDKT.vue'),
  },
  // 22
  {
    path: '/op/lkps/laporan/4.3.2/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/JIYD.vue'),
  },
  // 23
  {
    path: '/op/lkps/laporan/4.3.3/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/PPKEDOS.vue'),
  },
  // 24
  {
    path: '/op/lkps/laporan/4.3.4/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/PPKEDOS.vue'),
  },
  // 25
  {
    path: '/op/lkps/laporan/5.1.2.1/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/PEMDANA.vue'),
  },
  // 26
  {
    path: '/op/lkps/laporan/5.1.2.2/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/PENGDANA.vue'),
  },
  // 27
  {
    path: '/op/lkps/laporan/5.1.2.3/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/DANAPEL.vue'),
  },
  // 28
  {
    path: '/op/lkps/laporan/5.1.2.4/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/DANAPEL_PKM.vue'),
  },
  // 29
  {
    path: '/op/lkps/laporan/5.2.2.1/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/DPP.vue'),
  },
  // 30
  {
    path: '/op/lkps/laporan/5.2.2.2/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/DSP.vue'),
  },
  // 38
  {
    path: '/op/lkps/laporan/6.7.2/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/Kepmhs.vue'),
  },
  // 39
  {
    path: '/op/lkps/laporan/7.2.2/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/LKPS_ARPMD.vue'),
  },
  // 40
  {
    path: '/op/lkps/laporan/8.2.2/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/LKPS_ARPMD_D.vue'),
  },
  // 41
  {
    path: '/op/lkps/laporan/9.1.2.1/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/IPK.vue'),
  },
  // 42
  {
    path: '/op/lkps/laporan/9.1.2.2/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/LKPS_PRESTASIAKM.vue'),
  },
  // 43
  {
    path: '/op/lkps/laporan/9.1.2.3/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/LKPS_MSKT.vue'),
  },
  // 44
  {
    path: '/op/lkps/laporan/9.1.2.4/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/LKPS_TTSR.vue'),
  },
  // 45
  {
    path: '/op/lkps/laporan/9.1.2.5/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/LKPS_TTSR_R.vue'),
  },
  // 46
  {
    path: '/op/lkps/laporan/9.1.2.6/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/LKPS_TKPL.vue'),
  },
  // 47
  {
    path: '/op/lkps/laporan/9.2.2.1/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/LKPS_PIDTPS.vue'),
  },
  // 48
  {
    path: '/op/lkps/laporan/9.2.2.2/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/LKPS_KIDTPS.vue'),
  },
  // 49
  {
    path: '/op/lkps/laporan/9.2.2.3/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/LKPS_PJDMYY.vue'),
  },
  // 50
  {
    path: '/op/lkps/laporan/9.2.2.4/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/LKPS_PJDMY.vue'),
  },
  {
    path: '/op/lkps/laporan/6.1.2.2/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/LKPS_MKCP.vue'),
  },
  // 33
  {
    path: '/op/lkps/laporan/6.2.2.2/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/IHP.vue'),
  },
  {
    path: '/op/lkps/laporan/6.5.2.2/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/LKPS_JMBFP.vue'),
  },
  {
    path: '/op/lkps/laporan/6.5.2.4/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/LKPS_JMBFP_P.vue'),
  },
  {
    path: '/op/lkps/laporan/6.5.2.6/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/LKPS_JMBTA.vue'),
  },
  {
    path: '/op/lkps/laporan/6.6.2.1/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/LKPS_KALP.vue'),
  },
  {
    path: '/op/lkps/laporan/6.6.2.2/:lkps_table_id',
    component: () => import('@/views/pages/operator/lkps/sheets/LKPS_DTTA.vue'),
  },
]
  .map(route => {
    route.meta = { guard }
    return route
  })
