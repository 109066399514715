import Vue from 'vue'
import VueRouter from 'vue-router'

import auth from './routes/auth'
import superadmin from './routes/superadmin'
import operator from './routes/operator'
import reviewer from './routes/reviewer'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'auth-login' } },
    ...superadmin,
    ...operator,
    ...reviewer,
    ...auth,
    { path: '*', redirect: 'error-404' },
  ],
})

router.beforeEach((to, from, next) => { // #TODO IMPLEMENTATION AUTHENTICATION WITH BE
  if ('guard' in to.meta) {
    return to.meta.guard(next)
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
