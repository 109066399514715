import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    reviewer: {
      ts: '',
      department: [],
    },
    operatorLkpsPage: 1,
    reviewerLkpsPage: 1,
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    getReviewerInfo: state => state.reviewer,
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    UPDATE_REVIEWER_INFO(state, val) {
      state.reviewer = val
    },
    UPDATE_OPERATOR_LKPS_PAGE(state, val) {
      state.operatorLkpsPage = val
    },
    UPDATE_REVIEWER_LKPS_PAGE(state, val) {
      state.reviewerLkpsPage = val
    },
  },
  actions: {},
}
